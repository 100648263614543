import axios from 'axios';

const API_URL = '/api/';
// const API_URL = 'http://localhost:8000/api/';

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const getPosts = async () => {
    try {
        const response = await axiosInstance.get('posts/');
	console.log(response.data)
        return response.data;
    } catch (error) {
        console.error('There was an error fetching the posts!', error);
    }
};

export const getPostById = async (id) => {
    try {
        const response = await axiosInstance.get(`posts/${id}/`);
        return response.data;
    } catch (error) {
        console.error(`There was an error fetching the post with ID ${id}!`, error);
    }
};

export const getPostsByTag = async (tag_slug) => {
    try{
        const response = await axiosInstance.get(`tag/${tag_slug}/`);
        console.log(response.data)
        return response.data;
    } catch (error) {
        console.error(`There was an error fetching the posts with tag ${tag_slug}!`, error);
    }
  }

const BlogEndpoint = { getPosts, getPostById, getPostsByTag }

export default BlogEndpoint;