import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Nav = styled.nav`
  background: rgba(52, 58, 64, 0.9); // Dark background with 90% opacity
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
`;

const NavBrand = styled(Link)`
  color: #fff;
  font-size: 24px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const NavLinks = styled.div`
  a {
    color: #fff;
    margin-left: 15px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Navbar = () => (
  <Nav>
    <NavBrand to="/">BHSS</NavBrand>
    <NavLinks>
      <Link to="/">Home</Link>
      <Link to="/blog">Blog</Link>
      <Link to="/about">About</Link>
      <a href="https://github.com/WizardJR/" target="_blank" rel="noopener noreferrer">
        Github
      </a>
      {/* <Link to="/faq">FAQ</Link> */}
      {/* <Link to="/contact">Contact</Link> */}

    </NavLinks>
  </Nav>
);

export default Navbar;