import React from 'react';
import './About.css'
import HelmetComponent from '../../components/HelmetComponent/HelmetComponent';

const About = () => (
  <div className="content-tile about-tile">
    <HelmetComponent title="About" />
    {/* <h2 className='about-h2'>About BHSS</h2> */}
    <strong>Hello there</strong><br/><p>Whether you came from search results, or my janky repo, welcome!<br/>I am a recent Computer Science/Arts graduate of the University of New South  Wales in Sydney, Australia. I was particularly captivated by the infrastructure side of things, so I focused mainly on networks, cyber security and backend development during my degree.<br/>On the side I had also done projects where I developed solid foundations in frontend development, databases, machine learning and electrical engineering.<br/>At the moment, I'm still exploring different opportunities and trying to figure out my next steps.<br/>Thanks for visiting, and I hope you find something here that piques your interest. Stay tuned for more updates!</p>
  </div>
);

export default About;