// import React from 'react';
import './Home.css';
import HelmetComponent from '../../components/HelmetComponent/HelmetComponent';

const Home = () => {
  return <HelmetComponent title="BHSS" />
  // return (
  //     <div className="welcome-tile">
  //       Welcome
  //     </div>
  // );
};

export default Home;