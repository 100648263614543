import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer';

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  padding-top: 40px;
  padding-bottom:20px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

const Content = styled.main`
  flex: 1;
  padding: 20px;
  border-radius: 8px;
  background: transparent; // Make the main container transparent
  margin-bottom: 20px; // Margin at the bottom to separate tiles
`;

// const Footer = styled.footer`
//   background: rgba(52, 58, 64, 0.8); // Dark background with 80% opacity
//   color: #fff;
//   text-align: center;
//   padding: 15px 0;
// `;

const Layout = ({ children }) => {
  const location = useLocation();
  const showSidebar = location.pathname !== '/';

  return (
    <LayoutContainer>
      <Navbar/>
      <MainContainer>
        <ContentWrapper>
          <Content>{children}</Content>
          {showSidebar && <Sidebar />}
        </ContentWrapper>
      </MainContainer>
      <Footer/>
    </LayoutContainer>
  );
};

export default Layout;