import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background: rgba(52, 58, 64, 0.9); // Dark background with 90% opacity
  color: #fff;
  text-align: center;
  // position: fixed;
  width: 100%;
  bottom: 0;
  font-size: 12px;
`;

const Footer = () => (
  <FooterContainer>
    <p>&copy; 2024 BHSS. All Rights Reserved.</p>
  </FooterContainer>
);

export default Footer;