import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GlobalStyle from './GlobalStyle';
import Layout from './components/Layout/Layout';
import Home from './pages/Home/Home';
import FAQ from './pages/FAQ/FAQ';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact'
import PostList from './pages/Blog/PostList'
import PostDetail from './pages/Blog/PostDetail'
import PostsByTag from './pages/Blog/PostsByTag.js'
import './App.css'

const App = () => (
  <Router>
    <GlobalStyle />
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route exact path="/blog" element={<PostList />} />
        <Route path="/post/:id" element={<PostDetail />} />
        <Route path="/tag/:tag_slug" element={<PostsByTag />} />
      </Routes>
    </Layout>
  </Router>
);

export default App;