import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BlogEndpoint from '../../API/BlogAPI';
import './PostDetail.css'
import createDOMPurify from 'dompurify'
import HelmetComponent from '../../components/HelmetComponent/HelmetComponent';

const PostDetail = () => {
    const { id } = useParams();
    const [post, setPost] = useState(null);
  
    useEffect(() => {
      const fetchPost = async () => {
        try {
          const postData = await BlogEndpoint.getPostById(id);
          setPost(postData);
        } catch (error) {
          console.error('There was an error fetching the post!', error);
        }
      };
  
      fetchPost();
    }, [id]);
  
    if (!post) {
      return <div>Loading...</div>;
    }

    const DOMPurify = createDOMPurify(window)

    const createMarkup = (content) => {
      return { __html: DOMPurify.sanitize(content.replace(/src="\/media\//g, `src="${process.env.REACT_APP_MEDIA_URL}`)) };
    };

    return (
      <div className="content-tile post-tile">
        <HelmetComponent title={post.title} />
        <h2 className='post-h2'>{post.title}</h2>
	<br></br>
        <div className = 'post-content' dangerouslySetInnerHTML={createMarkup(post.content)} />
      </div>
    );
  };
export default PostDetail;