import React from 'react';
import './FAQ.css'

const FAQ = () => (
  <div className='content-tile faq-tile'>
    <h2>FAQ</h2>
    <br></br>
    <div>
      <strong>Q: What is this place?</strong>
      <br></br>
      <br></br>
      <p>A: I don't even know.</p>
    </div>
  </div>
);

export default FAQ;