import React from 'react';
import './Contact.css'

const Contact = () => (
  <div className='content-tile contact-tile'>
    <h2 className='contact-h2'>Contact Us</h2>
    <p>If you have any questions, feel free to reach out!</p>
  </div>
);

export default Contact;