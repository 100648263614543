import React, { useEffect, useState } from 'react';
import BlogEndpoint from '../../API/BlogAPI';
import { Link } from 'react-router-dom';
import HelmetComponent from '../../components/HelmetComponent/HelmetComponent';
import './PostsByTag.css';
import { useParams } from 'react-router-dom';

const PostsByTag = () => {
  const { tag_slug } = useParams();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchPostsByTag() {
      try {
        const data = await BlogEndpoint.getPostsByTag(tag_slug);
        setPosts(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching posts:", error);
        setLoading(false);
      }
    }

    fetchPostsByTag();
  }, [tag_slug]);

  if (loading) {
    return <div>Loading...</div>;
}

if (!posts || posts.length === 0) {
    return <div>No posts available.</div>;
}

return (
<div className="content-tiles">
  {posts.map(post => (
    <div key={post.id} className="content-tile">
      <HelmetComponent title="Recent Posts" />
      <Link className="posts-link" to={`/post/${post.id}`}>
        <h2>{post.title}</h2>
      </Link>
      <div className="content-excerpt">
      <div className="date-posted">
        Posted {new Date(post.created_at).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })}
      </div>
      <Link className="excerpt-link" to={`/post/${post.id}`}>
        <div className="excerpt-tile" dangerouslySetInnerHTML={{ __html: post.excerpt}}/>
      </Link>
      <div className="tags-container">
        {post.tags.map((tag, index) => (
          <Link key={index} className='tags-tiles' to={`/tag/${tag}`}>
            {tag}
          </Link>
        ))}
      </div>
      </div>
      </div>
  ))}
</div>
);

};

export default PostsByTag;